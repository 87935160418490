import { Frame, withHover } from "framer"

// Tvorba komponentu s hover funkcionalitou
const HoverableFrame = withHover(Frame)

export function ChangeTextColorWithHover() {
    return (
        <HoverableFrame
            size={300}
            background={null}
            center
            whileHover={{ color: "orange" }}
            initial={{ color: "black" }}
        >
            <Frame
                size={"auto"}
                background={null}
                text={"Prejdite cez mňa!"}
                center
            />
        </HoverableFrame>
    )
}
